import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { templateUpload } from '@/net/api';

interface MutationOptions {
	file: Blob;
}

const useTemplateUpload = (options?: UseMutationOptions<string, unknown, MutationOptions>) =>
	useMutation(
		(mutationOptions) =>
			templateUpload(mutationOptions).then((response) => response.expectSuccess()),
		options,
	);

export default useTemplateUpload;
