import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';

import useTemplateUpload from '@/net/reactQuery/mutations/useTemplateUpload';

export interface TemplateUploadProps {
	onChange(customTemplateId: string | null): void;
}

const TemplateUpload: React.FC<TemplateUploadProps> = ({ onChange }) => {
	const [file, setFile] = React.useState<File | null>(null);
	const $fileUpload = React.useRef<HTMLInputElement>(null);

	const templateUploadMutation = useTemplateUpload();

	const onClear = React.useCallback(() => {
		setFile(null);
		onChange(null);
	}, [onChange]);

	const onClick = React.useCallback(() => {
		$fileUpload.current?.click();
	}, [$fileUpload]);

	const onUpload: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
		async (event) => {
			if (event.target.files && event.target.files.length > 0) {
				const customTemplateId = await templateUploadMutation.mutateAsync({
					file: event.target.files[0],
				});
				onChange(customTemplateId);
				setFile(event.target.files[0]);
			}
		},
		[onChange, templateUploadMutation],
	);

	if (file) {
		return (
			<List>
				<ListItem
					disablePadding
					secondaryAction={
						<IconButton onClick={onClear}>
							<CloseIcon />
						</IconButton>
					}
				>
					<ListItemButton>
						<ListItemText primary={file.name} />
					</ListItemButton>
				</ListItem>
			</List>
		);
	}

	return (
		<FormControl fullWidth>
			<FormLabel sx={{ marginBottom: 1 }}>Upload custom template</FormLabel>
			<input
				accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				onChange={onUpload}
				ref={$fileUpload}
				style={{ display: 'none' }}
				type="file"
			/>
			<Button
				disabled={templateUploadMutation.isLoading}
				fullWidth
				onClick={onClick}
				variant="contained"
			>
				Upload custom template
			</Button>
			<FormHelperText>
				Optionally upload a custom Word document to use as the base Template.
			</FormHelperText>
		</FormControl>
	);
};

export default TemplateUpload;
