import 'react-app-polyfill/stable';
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import store from '@/store';
import '@/styles/index.css';
import { BrowserRouter } from 'react-router-dom';
import TestUserSelector from '@/components/TestUserSelector';
import ReactQueryProviders from '@/providers/ReactQueryProviders';
import {
	APPLICATIONINSIGHTS_CONNECTION_STRING,
	GOOGLE_TAG_MANAGER_AUTH,
	GOOGLE_TAG_MANAGER_ID,
	GOOGLE_TAG_MANAGER_PREVIEW,
	TEST_USERS_ENABLED,
} from '@/constants';
import Pages from '@/pages';
import { loadAppInsights, msal, ThemeProviders, useGoogleTagManager } from 'kes-common';
import { SnackbarProvider } from 'notistack';

import msalInstance from './authentication/msal';
import { Provider as AuthenticationProvider } from './providers/Authentication';
import FeatureFlagsProvider from './providers/FeatureFlags';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet';
import 'react-datepicker/dist/react-datepicker.css';

// eslint-disable-next-line import/prefer-default-export
export const appInsights = loadAppInsights(APPLICATIONINSIGHTS_CONNECTION_STRING);

const App = (): JSX.Element => {
	const { initialize: googleTagManagerInitialize } = useGoogleTagManager();

	React.useEffect(() => {
		msal.initialize(msalInstance);
	}, []);

	React.useEffect(() => {
		googleTagManagerInitialize({
			auth: GOOGLE_TAG_MANAGER_AUTH,
			gtmId: GOOGLE_TAG_MANAGER_ID,
			preview: GOOGLE_TAG_MANAGER_PREVIEW,
		});
	}, [googleTagManagerInitialize]);

	return (
		<ReactQueryProviders>
			{TEST_USERS_ENABLED && <TestUserSelector />}
			<ThemeProviders allowColorSchemeOverride={false}>
				<SnackbarProvider maxSnack={3}>
					<Provider store={store}>
						<AuthenticationProvider>
							<BrowserRouter>
								<FeatureFlagsProvider>
									<Pages />
								</FeatureFlagsProvider>
							</BrowserRouter>
						</AuthenticationProvider>
					</Provider>
				</SnackbarProvider>
			</ThemeProviders>
		</ReactQueryProviders>
	);
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
